import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SatisfacaoCliente } from '../../../model/controles/satisfacao-cliente/satisfacao-cliente';
import { Observable } from 'rxjs';
import { SatisfacaoClientePesquisa } from '../../../interface/controles/satisfacao-clientes/satisfacao-cliente-pesquisa';
import { RegistroPaginado } from '../../../interface/registroPaginado';
import { SatisfacaoClientePergunta } from '../../../model/controles/satisfacao-cliente/SatisfacaoClientePergunta';
import { ResultadosPerguntas } from '../../../interface/controles/satisfacao-clientes/resultadosPerguntas';
import { SatisfacaoClienteEmpresaRespostas } from '../../../interface/controles/satisfacao-clientes/satisfacaoClienteEmpresaRespostas';
import { SatisfacaoClienteRespostas } from '../../../interface/controles/satisfacao-clientes/satisfacaoClienteRespostas';

@Injectable({
  providedIn: 'root'
})
export class SatisfacaoClienteService {

  private url = `${environment.url}/controles/satisfacao-cliente`

  constructor(
    private httpClient: HttpClient
  ) { }


  public cadastrarPesquisa(satisfacaoCliente:SatisfacaoCliente): Observable<SatisfacaoCliente> {
    return this.httpClient.post<SatisfacaoCliente>(`${this.url}`,satisfacaoCliente);
  }
  public atualizarPesquisa(pergunta:SatisfacaoCliente): Observable<SatisfacaoCliente> {
    return this.httpClient.put<SatisfacaoCliente>(`${this.url}`,pergunta);
  }

  public cadastrarPerguntaEmPesquisa(pergunta:SatisfacaoClientePergunta,idPesquisa:number): Observable<SatisfacaoClientePergunta> {
    return this.httpClient.post<SatisfacaoClientePergunta>(`${this.url}/pergunta/${idPesquisa}`,pergunta);
  }
  public cadastrarRespostas(pergunta:SatisfacaoClienteRespostas[],idPesquisa:number,token:string): Observable<SatisfacaoClienteRespostas[]> {
    return this.httpClient.post<SatisfacaoClienteRespostas[]>(`${this.url}/resposta/${idPesquisa}/${token}`,pergunta);
  }
  public atualizarPerguntaEmPesquisa(pergunta:SatisfacaoClientePergunta,idPesquisa:number): Observable<SatisfacaoClientePergunta> {
    return this.httpClient.put<SatisfacaoClientePergunta>(`${this.url}/alterar-pergunta/${idPesquisa}`,pergunta);
  }

  public consultaPorPesquisaPorId(idPesquisa:number): Observable<SatisfacaoCliente> {
    return this.httpClient.get<SatisfacaoCliente>(`${this.url}/${idPesquisa}`);
  }

  public consultaStatusPesquisas(): Observable<SatisfacaoClientePesquisa> {
    return this.httpClient.get<SatisfacaoClientePesquisa>(`${this.url}/pesquisas-geral`);
  }
  public consultaPesquisasPaginada(paginaAtual:number,totalPagina:number,status:string): Observable<RegistroPaginado> {
    return this.httpClient.get<RegistroPaginado>(`${this.url}/${paginaAtual}/${totalPagina}/${status}`);
  }
  public consultaPorIdPesquisa(idPesquisa:number): Observable<SatisfacaoCliente> {
    return this.httpClient.get<SatisfacaoCliente>(`${this.url}/${idPesquisa}`);
  }

  public consultaRespostasPorEmpresa(idPesquisa:number): Observable<SatisfacaoClienteEmpresaRespostas[]> {
    return this.httpClient.get<SatisfacaoClienteEmpresaRespostas[]>(`${this.url}/respostas-empresa/${idPesquisa}`);
  }
  public consultaResultado(idPesquisa:number): Observable<ResultadosPerguntas[]> {
    return this.httpClient.get<ResultadosPerguntas[]>(`${this.url}/resultado/${idPesquisa}`);
  }
  public consultaRespostasPorIdPesquisa(idPesquisa:number): Observable<SatisfacaoCliente> {
    return this.httpClient.get<SatisfacaoCliente>(`${this.url}/respostas/${idPesquisa}`);
  }






  public apagarPerguntaDaPesquisa(idPesquisa:number): Observable<SatisfacaoCliente> {
    return this.httpClient.delete<SatisfacaoCliente>(`${this.url}/pergunta/${idPesquisa}`);
  }



}